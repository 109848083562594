<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <date-range-input :value="customRange" @input="loadHistory" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <v-spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <apex-chart
                v-if="!isLoading"
                height="440px"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

const voltagePattern = /^voltage_\d+$/
const temperaturePattern = /^t\d$/

const valueConverters = {
    accel_y: () => 1,
    fill_level: value => parseInt(value * 100),
    mass: value => (value / 1000).toFixed(2),
    temperature: value => value.toFixed(2),
    voltage_0: value => (value / 1000).toFixed(2),
    voltage_1: value => (value / 1000).toFixed(2),
    voltage_2: value => (value / 1000).toFixed(2),
    voltage_3: value => (value / 1000).toFixed(2),
    voltage_4: value => (value / 1000).toFixed(2),
}

const {
    VUE_APP_COLOR_PRIMARY: colorPrimary,
    VUE_APP_COLOR_ACCENT: colorAccent,
} = process.env

const voltageChartMockData = [
    {
        timestamp: '2021-11-30T15:30',
        sensor_data: {
            voltage_0: 7200,
            voltage_1: 7200,
            voltage_2: 6300,
            voltage_3: 2100,
            voltage_4: 12000,
        },
    },
    {
        timestamp: '2021-11-30T15:00',
        sensor_data: {
            voltage_0: 7000,
            voltage_1: 8000,
            voltage_2: 6000,
            voltage_3: 2000,
            voltage_4: 11000,
        },
    },
    {
        timestamp: '2021-11-30T14:30',
        sensor_data: {
            voltage_0: 8000,
            voltage_1: 8500,
            voltage_2: 7000,
            voltage_3: 4000,
            voltage_4: 11200,
        },
    },
    {
        timestamp: '2021-11-30T14:00',
        sensor_data: {
            voltage_0: 8400,
            voltage_1: 8200,
            voltage_2: 6000,
            voltage_3: 5100,
            voltage_4: 11300,
        },
    },
]

export default {
    name: 'AssetChartsCombinedView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        dataTypes: {
            type: Array,
            required: true,
        },
        simulated: {
            type: Boolean,
        },
    },
    data() {
        // default chart options
        let chartOptions = {
            chart: {
                type: this.isLineChart() ? 'line' : 'area',
                animations: {
                    enabled: false,
                },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true,
                },
            },
            stroke: {
                curve: 'straight',
                width: this.isLineChart() ? 3 : 5,
            },
            dataLabels: {
                enabled: false,
            },
            colors: this.getColors(),
            yaxis: {
                decimalsInFloat: 2,
                title: {
                    text: this.getYTitle(),
                },
                min: this.getYMinValue(),
                max: this.getYMaxValue(),
            },
            xaxis: {
                type: 'datetime',
            },
            tooltip: {
                shared: this.isLineChart(),
                x: {
                    format: 'dd.MM.yy HH:mm:ss',
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.7,
                    opacityTo: 0,
                },
            },
        }
        // custom chart options for specific cases
        if (
            ['bin', 'sbb-bin'].includes(this.assetType) &&
            this.dataType === 'distance'
        ) {
            chartOptions.yaxis = {
                ...chartOptions.yaxis,
                ...{
                    title: {
                        text: this.$i18n.t('yaxis_fillLevel'),
                    },
                    min: 0,
                    max: 100,
                },
            }
        }
        if (this.dataType === 'accel_y') {
            chartOptions.yaxis = {
                ...chartOptions.yaxis,
                ...{
                    show: false,
                    tickAmount: 2,
                    min: 0,
                    max: 2,
                },
            }
        }
        if (this.isVoltageChart()) {
            chartOptions.annotations = {
                yaxis: [
                    {
                        y: 3,
                        borderColor: '#000',
                        strokeDashArray: 3,
                    },
                ],
            }
            chartOptions.fill = {}
        }
        if (this.isTemperatureChart()) {
            chartOptions.fill = {}
        }

        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: chartOptions,
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    watch: {
        dataTypes() {
            this.chartOptions.colors = this.getColors()
            this.chartOptions.yaxis.title.text = this.getYTitle()
            this.chartOptions.yaxis.min = this.getYMinValue()
            this.chartOptions.yaxis.max = this.getYMaxValue()
            this.loadHistoryCustom(this.customRange)
        },
    },
    methods: {
        getColors() {
            if (this.dataTypes.length > 2) {
                return undefined
            }

            const defaultColors = [colorAccent, colorPrimary]
            return this.dataTypes.map((item, i) => {
                switch (item) {
                    case 'humidity':
                    case 'water_level':
                        return '#0077c2'
                    case 'temperature':
                        return colorAccent
                    default:
                        return defaultColors[i]
                }
            })
        },
        getYTitle() {
            if (this.isVoltageChart()) {
                return this.$t('yaxis_voltage')
            }

            if (this.isTemperatureChart()) {
                return this.$t('yaxis_temperature')
            }

            return this.dataTypes
                .map(item => this.$t('yaxis_' + item))
                .join(` ${this.$t('and')} `)
        },
        getYMinValue() {
            const min = Math.min(
                ...this.dataTypes.map(item => {
                    switch (item) {
                        case 'temperature':
                            return -25
                        case 't1':
                        case 't2':
                        case 't3':
                        case 't4':
                        case 't5':
                        case 't6':
                            return NaN // use min value
                        case 'battery':
                        case 'battery_voltage':
                        case 'co2':
                        case 'distance':
                        case 'fill_level':
                        case 'humidity':
                        case 'mass':
                        case 'power_0':
                        case 'power_1':
                        case 'power_2':
                        case 'power_3':
                        case 'voc':
                        case 'voltage_0':
                        case 'voltage_1':
                        case 'voltage_2':
                        case 'voltage_3':
                        case 'voltage_4':
                        case 'volume':
                            return 0
                    }
                })
            )

            return Number.isNaN(min) ? undefined : min
        },
        getYMaxValue() {
            const max = Math.max(
                ...this.dataTypes.map(item => {
                    switch (item) {
                        case 'voltage_0':
                        case 'voltage_1':
                        case 'voltage_2':
                        case 'voltage_3':
                        case 'voltage_4':
                            return 20
                        case 'temperature':
                            return 50
                        case 't1':
                        case 't2':
                        case 't3':
                        case 't4':
                        case 't5':
                        case 't6':
                            return NaN // use max value
                        case 'battery':
                        case 'fill_level':
                        case 'humidity':
                            return 100
                        case 'power_0':
                        case 'power_1':
                        case 'power_2':
                        case 'power_3':
                            return 3000
                    }
                })
            )

            return Number.isNaN(max) ? undefined : max
        },
        isVoltageChart() {
            return this.dataTypes.every(type => voltagePattern.test(type))
        },
        isTemperatureChart() {
            return this.dataTypes.every(type => temperaturePattern.test(type))
        },
        isLineChart() {
            return this.isVoltageChart() || this.isTemperatureChart()
        },
        async loadHistory({ startDate, endDate }) {
            this.isLoading = true
            this.startDate = moment(startDate)
            this.endDate = moment(endDate)
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = this.simulated
                ? voltageChartMockData
                : await this.loadData()
            this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let url =
                'measurements/?' +
                `timestamp_min=${encodeURIComponent(this.startDate.format())}` +
                `&timestamp_max=${encodeURIComponent(this.endDate.format())}` +
                `&tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            const chartData = this.dataTypes.map(dataType => {
                let converter = valueConverters[dataType] || (value => value)
                let data = results.filter(item =>
                    Object.prototype.hasOwnProperty.call(
                        item.sensor_data,
                        dataType
                    )
                )

                if (dataType === 'distance') {
                    if (this.assetType === 'bin') {
                        converter = measurementHelper.convertToBinLevel
                    } else if (this.assetType === 'sbb-bin') {
                        converter = measurementHelper.convertToSbbBinLevel
                    }
                }

                return data.map(item => [
                    item.timestamp,
                    converter(item.sensor_data[dataType]),
                ])
            })

            this.series = chartData.map((data, i) => ({
                name: this.$t(`router.${this.dataTypes[i]}`),
                data,
            }))
        },
    },
}
</script>

<i18n>
{
    "en": {
        "and": "and",
        "yaxis_battery": "Battery (%)",
        "yaxis_battery_voltage": "Battery voltage (V)",
        "yaxis_co2": "Carbon dioxide (ppm)",
        "yaxis_distance": "Distance (m)",
        "yaxis_fillLevel": "Fill level (%)",
        "yaxis_fill_level": "Fill level (%)",
        "yaxis_humidity": "Humidity (%)",
        "yaxis_lower_loop": "Lower loop",
        "yaxis_mass": "Mass (t)",
        "yaxis_potential": "Potential",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Temperature (°C)",
        "yaxis_t2": "Temperature (°C)",
        "yaxis_t3": "Temperature (°C)",
        "yaxis_t4": "Temperature (°C)",
        "yaxis_t5": "Temperature (°C)",
        "yaxis_t6": "Temperature (°C)",
        "yaxis_temperature": "Temperature (°C)",
        "yaxis_upper_loop": "Upper loop",
        "yaxis_voc": "Volatile organic compounds (ppb)",
        "yaxis_voltage": "Voltage (kV)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Water level"
    },
    "de": {
        "and": "und",
        "yaxis_battery": "Batterie (%)",
        "yaxis_battery_voltage": "Batteriespannung (V)",
        "yaxis_co2": "Kohlenstoffdioxid (ppm)",
        "yaxis_distance": "Distanz (m)",
        "yaxis_fillLevel": "Füllstand (%)",
        "yaxis_fill_level": "Füllstand (%)",
        "yaxis_humidity": "Luftfeuchtigkeit (%)",
        "yaxis_lower_loop": "Untere Schlaufe",
        "yaxis_mass": "Masse (t)",
        "yaxis_potential": "Potential",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Temperatur (°C)",
        "yaxis_t2": "Temperatur (°C)",
        "yaxis_t3": "Temperatur (°C)",
        "yaxis_t4": "Temperatur (°C)",
        "yaxis_t5": "Temperatur (°C)",
        "yaxis_t6": "Temperatur (°C)",
        "yaxis_temperature": "Temperatur (°C)",
        "yaxis_upper_loop": "Obere Schlaufe",
        "yaxis_voc": "Flüchtige organische Verbindungen (ppb)",
        "yaxis_voltage": "Spannung (kV)",
        "yaxis_volume": "Volumen (m³)",
        "yaxis_water_level": "Wasserpegel"
    },
    "it": {
        "and": "e",
        "yaxis_battery": "Batteria (%)",
        "yaxis_battery_voltage": "Tensione della batteria (V)",
        "yaxis_co2": "Anidride carbonica (ppm)",
        "yaxis_distance": "Distanza (m)",
        "yaxis_fillLevel": "Riempimento (%)",
        "yaxis_fill_level": "Riempimento (%)",
        "yaxis_humidity": "Umidità (%)",
        "yaxis_lower_loop": "Loop inferiore",
        "yaxis_mass": "Massa (t)",
        "yaxis_potential": "Potential",
        "yaxis_power_0": "L0",
        "yaxis_power_1": "L1",
        "yaxis_power_2": "L2",
        "yaxis_power_3": "L3",
        "yaxis_tau": "Tau",
        "yaxis_t1": "Temperatura (°C)",
        "yaxis_t2": "Temperatura (°C)",
        "yaxis_t3": "Temperatura (°C)",
        "yaxis_t4": "Temperatura (°C)",
        "yaxis_t5": "Temperatura (°C)",
        "yaxis_t6": "Temperatura (°C)",
        "yaxis_temperature": "Temperatura (°C)",
        "yaxis_upper_loop": "Loop superiore",
        "yaxis_voc": "Composti organici volatili (ppb)",
        "yaxis_voltage": "Voltaggio (kV)",
        "yaxis_volume": "Volume (m³)",
        "yaxis_water_level": "Livello dell'acqua"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .chart {
        border: none;
    }
}
</style>
